<template>
  <div class="zh_leftNav">
    <div class="content">
      <div class="title dp_f cur_p" @click="goHome">
        <Logo/>
      </div>
      <div class="nav">
        <div
          class="single dp_f cur_p"
          :class="item.menuId === menuChecked.menuId ? 'active' : ''"
          v-for="(item, index) in childrenChecked"
          :key="index"
          @click.stop="goLink(item)"
        >
          <i  class="icon iconfont" :class="item.icon"/>
          <div class="name" >{{ item.title }}</div>
          <i class="collection iconfont icon-a-ziyuan16" v-if="item.isLike"  @click='like(item,index)'/>
          <i  v-else class="collection iconfont icon-a-ziyuan15" @click.stop='like(item,index)'/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCurrentInstance, ref} from "vue";
import {useRouter} from "vue-router";
import {getMenuDislike, getMenuLike} from '../utils/api/system.js'
import Logo from "@components/logo.vue";
import useMenu from "@utils/hooks/useMenu";

export default {
  name: "leftNav",
  props: {},
  components: {
    Logo
  },
  data() {
    return {};
  },
  methods: {},
  setup(props, { emit }) {
    const router = useRouter();
    const {childrenChecked, menuChecked} = useMenu();
    const {proxy} = getCurrentInstance()
    const upDateHome = function () {
      emit("change", {});
    };
    const goLink = (item) => {
      router.push({
        path: item.jumpUrl,
      });
    };
    const like = async (item,index)=>{
      if(item.isLike){
        //取消收藏
        let data = await getMenuDislike({
          menuId:item.menuId
        })
        if (data.code !== 0) {
          proxy.$message.error(data.msg)
          return
        }
         proxy.$message.success('取消收藏成功')
        childrenChecked.value[index].isLike = false
      }else{
        //收藏
        let data = await getMenuLike({
          menuId:item.menuId
        })
        if (data.code !== 0) {
          proxy.$message.error(data.msg)
          return
        }
        proxy.$message.success('已添加到首页展示')
        childrenChecked.value[index].isLike = true
      }
    }
    //返回首页
    const goHome = ()=>{
      router.push({
        path:'/home'
      })
    }
    const showModal = ref(false)
    const addShow = ()=>{
      showModal.value = true
    }
    return {
      showModal,
      addShow,
      childrenChecked,
      upDateHome,
      goLink,
      menuChecked,
      like,
      goHome
    };
  },
};
</script>
<script setup></script>

<style lang="scss" scoped>
.zh_leftNav {
  transition: background-color 0.5s linear;
  width: 200px;
  background-color: var(--el-color-background);
  height: 100vh;
  .content {
    border-radius: 0 24px 0 0;
    height: 100%;
    background: #fff;
    .title {
      padding-top: 10px;
      justify-content: center !important;
      .company_name {
        margin-left: 8px;
      }
    }
    .nav {
      padding-right: 24px;
      margin-top: 60px;
      .single {
        border-radius: 0 16px 16px 0;
        justify-content: center !important;
        height: 48px;
        transition: 0.5s all linear;
        background: #fff;
        color: $fsColor2;
        margin-bottom: 30px;
        font-size: $fs14;
        padding-left: 20px;
        .icon {
        }
        .collection {
          font-size: 12px;
        }
        .name {
          margin: 0 10px 0 10px;
          width: 76px;
        }
        &.active {
          background-color: var(--el-color-background);
          color: #fff;
        }
        &:hover {
          background-color: var(--el-color-background);
          color: #fff;
        }
      }
    }
  }
}
</style>
