<template>
  <div class="zh_main dp_f">
    <LeftNav />
    <div class="mainBox">
      <Header></Header>
      <div class="routerMain">
        <router-view v-slot="{ Component }">
          <transition name="layout-main-page">
            <div :key="route.path" class="box">
              <component :is="Component"></component>
            </div>
          </transition>
        </router-view>
        </div>
    </div>
  </div>
</template>

<script>

import {useRoute} from "vue-router";
import Header from '../components/header.vue'
import LeftNav from '../components/leftNav.vue'
import {useLoading} from "@utils/tool/loading"

export default {
  name:"layout",
  components: {
    Header,
    LeftNav
  },
  setup (props) {
    const route = useRoute()
    const {loading} = useLoading()
    return {
      route,
      loading
    }
  }
}
</script>

<style lang='scss' scoped>
  .zh_main{
    justify-content: flex-start !important;
    align-items: flex-start !important;
    .mainBox{
        width: calc(100% - 200px);
        min-width: 1200px;
    }
    .routerMain{
      height: calc(100vh - 56px);
      box-sizing: border-box;
      overflow-y: auto;
      background: #F2F4F7;
      .box{
        height: 100%;
      }
    }
  }
  .user .anticon {
    margin-left: 10px;
    font-size: 22px;
    cursor: pointer;
  }


.layout-main-page-enter-from {
	opacity: 0;
	transform: translateX(-30px);
}
.layout-main-page-enter-active {
	opacity: 1;
  transform: translateX(0%);
	transition: all 0.5s linear;
}
.layout-main-page-leave-to {
	opacity: 1;
}
.layout-main-page-leave-active {
	opacity: 0;
	transition: opacity 200ms;
}


</style>
