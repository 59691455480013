<template>
  <div>
    <div class="zh_header dp_f">
      <div class="left dp_f">
        <div class="menu">
          <el-badge :value="item.count" class="item dp_f cur_p" v-for="(item, index) in menu"
                    :class="item.menuId === menuChecked.fatherMenuId ? 'active' : ''"
                    :key="index"
                    @click="goLink(item)">

            <i class="icon iconfont"
               :class="item.menuId === menuChecked.fatherMenuId ? item.icon : item.icon +'-unSelected'"/>
            <div class="name">{{ item.title }}</div>
          </el-badge>
        </div>
      </div>
      <user-info-top where="index"></user-info-top>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import {useRouter} from "vue-router";
import UserInfoTop from "@components/common/userInfo/index.vue";
import useMenu from "@utils/hooks/useMenu";

export default {
  name: "zhHead",
  components: {
    UserInfoTop
  },
  setup() {
    const router = useRouter();
    const {menu, menuChecked} = useMenu()
    const goLink = (item) => {
      if (!item.jumpUrl) return;
      document.querySelector("html").className = item.bg;
      router.push({
        path: item.jumpUrl,
      });
    };
    return {
      menu,
      goLink,
      menuChecked,
    };
  },
};
</script>


<style lang="scss">
.zh_header {
  transition: background-color 0.5s linear;
  background-color: var(--el-color-background);
  padding: 0 20px;
  height: 56px;
  white-space: nowrap;

  .left {
    .logo {
      display: flex;
      align-items: center;
      width: 200px;

      .logoImg {
        width: 50px;
        height: 40px;
      }

      .company_name {
        font-size: 18px;
        font-weight: bold;
        color: #161829;
        margin-left: 6px;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      margin-left: calc(100vw * 0.03);

      .item {
        padding: 0 calc(100vw * 0.01);
        margin-right: 1vw;
        line-height: 32px;
        width: max-content;
        font-size: $fs14;
        height: 32px;
        border-radius: $borderRadius8;
        transition: 0.5s all ease-in-out;
        color: #fff;

        &.active {
          background: #fff;
          color: var(--el-color-background);
        }

        &:hover {
          background: #fff;
          color: var(--el-color-background);
        }

        .icon {
          margin-right: 8px;
          position: relative;
          // top: 1px;
        }
      }

      // .item {
      //   line-height: 34px;
      //   font-size: $fs14;
      //   width: 114px;
      //   height: 34px;
      //   transition: 0.5s all linear;
      //   border-radius: $borderRadius8;
      //   justify-content: center !important;
      //   margin-right: 42px;
      //   opacity: 0.6;
      //   color: #fff;
      //   &.active {
      //     background: #fff;
      //     color: var(--el-color-background);
      //     opacity: 1;
      //   }
      //   &:hover {
      //     background: #fff;
      //     color: var(--el-color-background);
      //     opacity: 1;
      //   }
      //   .icon {
      //     position: relative;
      //     top: 1px;
      //     margin-right: 8px;
      //   }
      // }
    }
  }
}
</style>
